import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const WhyChooseUs = () => {
  return (
    <div className="whychooseus_container">
      <div
        className="whychooseus_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <h1>What we do?</h1>
      </div>

      <div className="whychooseus_content">
        <div
          className="whychooseus_content_icon"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <i class="fa-solid fa-code"></i>
        </div>
        <div
          className="whychooseus_content_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <h3>Software Development</h3>
          <p>
            Vishwalatarati excels in crafting tailored solutions designed to
            streamline processes and enhance efficiency across diverse
            industries. Our team of skilled developers possesses the expertise
            to conceptualize, design, and implement software solutions that
            precisely align with our clients' unique needs and objectives.
          </p>
        </div>
      </div>

      <div className="whychooseus_content">
        <div
          className="whychooseus_content_icon"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <i class="fa-solid fa-person-chalkboard"></i>
        </div>
        <div
          className="whychooseus_content_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <h3>Industrial Training</h3>
          <p>
            At Vishwalatarati, we understand the crucial role of continuous
            learning and skill development in today's rapidly evolving
            technological landscape. That's why we offer comprehensive
            industrial training programs tailored to meet the specific needs and
            challenges of various industries.
          </p>
        </div>
      </div>

      <div
        className="read_more_btn"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <Link to="/services">Read More</Link>
      </div>
    </div>
  );
};

export default WhyChooseUs;

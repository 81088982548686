import React from "react";
import WhoWeAreImg from "../images/img.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function WhoWeAre() {
  return (
    <div className="whoweare_container">
      <div className="whoweare_content">
        <h1 data-aos="fade-in" data-aos-duration="1000">
          Who We Are?
        </h1>
        <p data-aos="fade-in" data-aos-duration="2000">
          At Vishwalatarati, we are a team of passionate professionals dedicated
          to driving innovation and efficiency through our specialized services.
          With expertise in outsourced product development, data engineering,
          and digital strategy design, we empower organizations to accelerate
          their time-to-market, optimize costs, and focus on their core
          competencies. Our mission is to craft experiences that simplify lives,
          whether through tailored software solutions, intuitive mobile
          applications, or engaging websites. By leveraging data-driven
          insights, we help businesses make informed decisions and unlock
          actionable intelligence for sustainable growth. Committed to fostering
          creativity, collaboration, and personal growth, we invite you to join
          us on our journey towards success.
        </p>
        <div
          className="know_more_btn"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <Link to="/products">Know More</Link>
        </div>
      </div>

      <div
        className="whoweare_image"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <img src={WhoWeAreImg} alt="" />
      </div>
    </div>
  );
}

export default WhoWeAre;

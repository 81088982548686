import Header from "../components/Header";
import training from "../images/training.jpg";
import app from "../images/app.jpg";
import web from "../images/web.jpg";
import digital from "../images/digital.jpg";
import data from "../images/data.jpg";
import sw from "../images/sw.jpg";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function Welcome() {
  return (
    <div className="welcome">
      <div className="welcome_content">
        <div
          className="welcome_content_small_heading"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>Welcome to Vishwalatarati</p>
        </div>

        <div
          className="welcome_content_heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1>
            Vishwalatarati <br />
            <span>Services</span>
          </h1>
        </div>

        <div
          className="welcome_content_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>
            At Vishwalatarati, we provide outsourced product development, data
            engineering, and Digital Strategy Design services that are key
            drivers of innovation and efficiency. They enable organizations to
            accelerate time-to-market, optimize costs, and focus on core
            competencies. Kanaka can help build a powerful ecosystem that drives
            efficiency, innovation, and strategic growth for organizations.
          </p>
        </div>

        {/* <div className="mail_to">
          <a href="/">
            <p>Email us your resume at</p>
            <p>vishwalatarati@gmail.com</p>
          </a>
        </div> */}
      </div>

      <div
        className="welcome_image"
        data-aos="slide-up"
        data-aos-duration="1000"
      >
        <img
          src="https://kanakasoftware.com/assets/images/main/Services.jpg"
          alt="welcome_image"
        />
      </div>
    </div>
  );
}

function OurServices() {
  return (
    <div className="our_services">
      <div
        className="our_services_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <p>
          We design and develop experiences that make people's lives simple!
        </p>
      </div>

      <div className="our_services_container">
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={sw} alt="our_service" />
          <h2>Software Develoment</h2>
          <p>
            Crafting tailored software solutions to streamline processes and
            enhance efficiency, ensuring seamless digital experiences.
          </p>
        </div>
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={app} alt="our_service" />
          <h2>Mobile Application</h2>
          <p>
            Designing and developing intuitive mobile applications that empower
            users with convenience and accessibility on the go.
          </p>
        </div>
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={web} alt="our_service" />
          <h2>Website Develoment</h2>
          <p>
            Building engaging websites that captivate audiences and drive
            business goals through innovative design and robust functionality.
          </p>
        </div>
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={data} alt="our_service" />
          <h2>Data & Analytics</h2>
          <p>
            Leveraging data-driven insights to optimize decision-making, enhance
            performance, and unlock actionable intelligence for sustainable
            growth.
          </p>
        </div>
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={digital} alt="our_service" />
          <h2>Digital Strategy & Design</h2>
          <p>
            Crafting comprehensive digital strategies and compelling designs to
            align brand objectives with user needs, fostering meaningful
            connections and driving success.
          </p>
        </div>
        <div
          className="our_service"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <img src={training} alt="our_service" />
          <h2>Industrial Training</h2>
          <p>
            Providing hands-on training programs tailored to industry needs,
            equipping professionals with the skills and knowledge required to
            excel in their fields and adapt to evolving technologies.
          </p>
        </div>
      </div>
    </div>
  );
}

function CareerPage() {
  return (
    <div className="contact_page">
      <Header />
      <Welcome />
      <OurServices />
      <Footer />
    </div>
  );
}

export default CareerPage;

import Header from "../components/Header";
import WelcomeToVLR from "../components/WelcomeToVLR";
import Footer from "../components/Footer";
import ajay from "../images/ajay_gavasane.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function AboutPage() {
  return (
    <div className="about_page">
      <Header />
      <div className="vision">
        <div
          className="content_heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h3>Welcome to Vishwalarati</h3>
        </div>
        <div className="vision_content">
          <div
            className="vision_text"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <p>
              Welcome to Vishwalatarati, where innovation meets efficiency and
              excellence is our standard. We specialize in outsourced product
              development, data engineering, and digital strategy design
              services, serving as the driving force behind organizations'
              journey towards accelerated time-to-market, cost optimization, and
              enhanced core competencies. At Vishwalatarati, we craft
              experiences that simplify lives, whether through tailored software
              solutions streamlining processes, intuitive mobile applications
              providing on-the-go accessibility, or engaging websites driving
              business goals through innovative design. Leveraging data-driven
              insights, we optimize decision-making and unlock actionable
              intelligence for sustainable growth. Our comprehensive digital
              strategies and compelling designs align brand objectives with user
              needs, fostering meaningful connections and driving success.
            </p>
          </div>
          <div
            className="vision_img"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <img
              src="https://kanakasoftware.com/assets/images/main/Services.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <WelcomeToVLR /> */}

      <div className="mission">
        <div
          className="content_heading"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3>Mission</h3>
        </div>
        <div className="mission_content">
          <div
            className="mission_img"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <img
              src="https://kanakasoftware.com/assets/images/main/About_Us.jpg"
              alt=""
            />
          </div>
          <div
            className="mission_text"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <p>
              Our mission at Vishwalatarati is to drive innovation and
              efficiency by providing specialized services in outsourced product
              development, data engineering, and digital strategy design. We aim
              to be the catalyst for organizations' success, enabling them to
              accelerate time-to-market, optimize costs, and enhance their core
              competencies. Through our commitment to crafting experiences that
              simplify lives, we strive to create tailored software solutions,
              intuitive mobile applications, and engaging websites that meet the
              evolving needs of businesses and users alike. Leveraging
              data-driven insights, we empower organizations to make informed
              decisions and unlock actionable intelligence for sustainable
              growth. Our ultimate goal is to foster a culture of creativity,
              collaboration, and personal growth, ensuring that every client and
              team member achieves their full potential.
            </p>
          </div>
        </div>
      </div>

      <div className="vision">
        <div
          className="content_heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h3>Vision</h3>
        </div>
        <div className="vision_content">
          <div
            className="vision_text"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <p>
              Our vision at Vishwalatarati is to be a leading force in driving
              transformative change and innovation across industries worldwide.
              We aspire to continuously push the boundaries of what's possible
              in outsourced product development, data engineering, and digital
              strategy design, setting new standards for excellence and
              efficiency. By delivering unparalleled value to our clients, we
              aim to become their trusted partner of choice, known for our
              commitment to delivering impactful solutions that drive growth and
              success. Through visionary leadership, cutting-edge technology,
              and a relentless pursuit of excellence, we envision a future where
              organizations can thrive in a rapidly evolving digital landscape.
            </p>
          </div>
          <div
            className="vision_img"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <img
              src="https://kanakasoftware.com/assets/images/main/Data_Science.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="mission executives">
        <div
          className="content_heading"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3>Meet Our Executive Profiles</h3>
        </div>
        <div className="mission_content">
          <div
            className="mission_img"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <div className="profile">
              <img src={ajay} alt="" />
            </div>
          </div>
          <div
            className="mission_text"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <div>
              <h3>Ajay Gavasane</h3>
              <h5>Founder</h5>
              <a>LinkedIn</a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;

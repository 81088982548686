import Header from "../components/Header";
import HeroCarousel from "../components/HeroCarousel";
import OurServices from "../components/OurServices";
import WhoWeAre from "../components/WhoWeAre";
import Footer from "../components/Footer";

function HomePage() {
  return (
    <div className="home_page">
      <Header />
      <HeroCarousel />
      <OurServices />
      <WhoWeAre />
      <Footer />
    </div>
  );
}

export default HomePage;

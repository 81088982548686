import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function JoinUs() {
  return (
    <div className="join_us">
      <div className="join_us_content">
        <div
          className="join_us_content_small_heading"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>Join Us</p>
        </div>

        <div
          className="join_us_content_heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1>
            Become Part of <br />
            <span>Our Team!</span>
          </h1>
        </div>

        <div
          className="join_us_content_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>
            Indulge in an environment where creativity flourishes, collaboration
            thrives, and personal growth is encouraged at every turn. Join our
            dynamic team and embark on an exciting adventure towards success!
            Together, we can accomplish remarkable feats and cultivate a
            rewarding career path. Discover our array of career opportunities
            and unlock your full potential with our supportive team!
          </p>
        </div>

        <div className="mail_to" data-aos="fade-in" data-aos-duration="2000">
          <a href="/">
            <p>Email us your resume at</p>
            <p>vishwalatarati@gmail.com</p>
          </a>
        </div>
      </div>

      <div
        className="join_us_image"
        data-aos="slide-up"
        data-aos-duration="1000"
      >
        <img
          src="https://kanakasoftware.com/assets/images/main/Careers.jpg"
          alt="join_us_image"
        />
      </div>
    </div>
  );
}

function JoinOurTeam() {
  return (
    <div className="join_our_team">
      <div
        className="join_our_team_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <p>
          Discover available job positions, Identify your ideal fit, and Apply!
        </p>
      </div>

      <div
        className="join_our_team_container"
        data-aos="flip-up"
        data-aos-duration="1000"
      >
        <div className="join_our_team_content">
          <h1>Join Our Team</h1>

          <p>
            Explore a range of job opportunities, pinpoint the role that aligns
            with your skills and aspirations, and confidently submit your
            application to pursue your career goals.
          </p>

          <p>
            Email your resume to{" "}
            <a href="mailto:vishwalarati@gmail.com">vishwalatarati@gmail.com</a>{" "}
            <br />
            <b>OR</b> Complete the following Application Form.
          </p>

          <button type="submit">Application Form</button>
        </div>
      </div>
    </div>
  );
}

function CareerPage() {
  return (
    <div className="contact_page">
      <Header />
      <JoinUs />
      <JoinOurTeam />
      <Footer />
    </div>
  );
}

export default CareerPage;

import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function GetInTouch() {
  return (
    <div className="get_in_touch">
      <div className="get_in_touch_content">
        <div
          className="get_in_touch_content_small_heading"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>Get In Touch</p>
        </div>

        <div
          className="get_in_touch_content_heading"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1>
            Let's Start a <br />
            <span>Conversation!</span>
          </h1>
        </div>

        <div
          className="get_in_touch_content_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>
            We value open communication and are committed to providing prompt
            and helpful support to address any inquiries or concerns you may
            have. Our team is dedicated to ensuring your experience with us is
            positive and productive. Whether you're a customer, partner, or
            simply curious about our services, don't hesitate to reach out. We
            look forward to hearing from you and starting a meaningful dialogue.
          </p>
        </div>

        <div className="mail_to" data-aos="fade-in" data-aos-duration="2000">
          <a href="/">
            <p>Email To</p>
            <p>vishwalatarati@gmail.com</p>
          </a>
        </div>
      </div>

      <div
        className="get_in_touch_image"
        data-aos="slide-up"
        data-aos-duration="1000"
      >
        <img
          src="https://kanakasoftware.com/assets/images/main/Contact%20Us.jpg"
          alt="get_in_touch_image"
        />
      </div>
    </div>
  );
}

function ContactInfo() {
  return (
    <div className="contact_info">
      <div
        className="contact_info_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <p>Reach Out, Connect, and Let's Make Things Happen Together!</p>
      </div>

      <div
        className="contact_info_container"
        data-aos="flip-up"
        data-aos-duration="1000"
      >
        <div className="form_container contact_form">
          <h3>Connect Us</h3>
          <form className="form">
            <input
              placeholder="First Name *"
              name="Name"
              type="text"
              required
            />
            <input
              placeholder="Last Surname *"
              name="Surname"
              type="text"
              required
            />
            <input
              placeholder="Your Phone *"
              name="Phone"
              type="text"
              required
            />
            <input
              placeholder="Your Email *"
              name="Email"
              type="email"
              required
            />
            <textarea placeholder="Your Message" name="Message" />
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="contact_info_content">
          <h1>Contact Info</h1>

          <p>
            <i className="ri-map-pin-2-fill"></i> 783, Swami Vivekananda Nagar,
            <br />
            Near Solapur Airport, Solapur-413002, Maharashtra.
          </p>

          <p>
            <i class="ri-phone-fill"></i> +91 7620131908, +91 9146099742
          </p>

          <p>
            <i class="ri-mail-fill"></i> vishwalatarati@gmail.com
          </p>

          <div className="socials">
            <a href="/">
              <i class="ri-twitter-x-line"></i>
            </a>
            <a href="/">
              <i class="ri-whatsapp-fill"></i>
            </a>
            <a href="https://www.instagram.com/vishwalatarati?igsh=YTQwZjQ0NmI0OA==">
              <i class="ri-instagram-fill"></i>
            </a>
            <a href="https://www.linkedin.com/in/vishwalatarati-vlr-digital-solutions-privated-limited-6284152b4/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
              <i class="ri-linkedin-box-fill"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactPage() {
  return (
    <div className="contact_page">
      <Header />
      <GetInTouch />
      <ContactInfo />
      <Footer />
    </div>
  );
}

export default ContactPage;

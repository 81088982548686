import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import carousel1 from "../images/slide1.jpg";
// import carousel2 from "../images/slide3.jpg";
import carousel3 from "../images/slide3.jpg";

function IndividualIntervalsExample() {
  return (
    <Carousel fade>
      <Carousel.Item interval={2500}>
        <img className="d-block w-100 hero" src={carousel3} alt="First slide" />
      </Carousel.Item>
      {/* <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={carousel3} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={carousel3} alt="First slide" />
      </Carousel.Item> */}
    </Carousel>
  );
}

export default IndividualIntervalsExample;

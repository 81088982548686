import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="pre_footer">
        <div className="quick_links">
          <h4>Our Services</h4>
          <p>
            <a href="/">Software Development</a>
          </p>
          <p>
            <a href="/">Mobile Applications</a>
          </p>
          <p>
            <a href="/">Website Development</a>
          </p>
          <p>
            <a href="/">Data & Analytics</a>
          </p>
          <p>
            <a href="/">Digital Strategy & Design</a>
          </p>
          <p>
            <a href="/">Industrial Training</a>
          </p>
        </div>
        <div className="high_school">
          <h4>Address</h4>
          <p>
            <i className="ri-map-pin-2-fill"></i> 783, Swami Vivekananda Nagar,
            Near Solapur Airport, Solapur-413002, Maharashtra.
          </p>
        </div>

        <div className="contact">
          <h4>Contact</h4>
          <p>
            <a href="mailto:vishwalarati@gmail.com">
              <i className="ri-mail-fill"></i>
              vishwalarati@gmail.com
            </a>
          </p>
          <p>
            <i className="ri-phone-fill"></i> +91 7620131908
          </p>
          <p>
            <i className="ri-phone-fill"></i> +91 9146099742
          </p>
        </div>

        <div className="quick_links">
          <h4>Quick Links</h4>
          <p>
            <a href="/">Home</a>
          </p>
          <p>
            <a href="/about">About Us</a>
          </p>
          <p>
            <a href="/services">Our Services</a>
          </p>
          <p>
            <a href="/contact">Contact</a>
          </p>
          <p>
            <a href="/career">Career</a>
          </p>
        </div>
      </div>

      <div className="main_footer">
        <p>
          <span id="currentYear"></span>
          Copyright &#169; 2024 | Vishwalatarati Digital Solutions Pvt Ltd,
          Solapur | All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
